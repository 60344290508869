<template>
    <form class="form" @submit.prevent="updatePassword">
        <div class="form__inputs-content">
            <div class="input-form">
                <label class="form__input-label form--required">
                    Old Password
                </label>

                <input type="password" class="form__input" placeholder="******" v-model="password.password">


                <div class="form__input-error" v-if="errors.fields.password">
                    {{ errors.fields.password[0] }}
                </div>
            </div>

            <div class="input-form">
                <label class="form__input-label form--required">
                    New Password
                </label>

                <input type="password" class="form__input" placeholder="******" v-model="password.newPassword">

                <div class="form__input-error" v-if="errors.fields.newPassword">
                    {{ errors.fields.newPassword[0] }}
                </div>
            </div>

            <div class="input-form">
                <label class="form__input-label form--required">
                    New Password Confirmation
                </label>

                <input type="password" class="form__input" placeholder="******"
                    v-model="password.newPasswordConfirmation">

                <div class="form__input-error" v-if="errors.fields.newPasswordConfirmation">
                    {{ errors.fields.newPasswordConfirmation[0] }}
                </div>
            </div>
        </div>

        <div class="form__buttons">
            <button class="button button--success" v-if="!buttonLoader">
                <img src="@/assets/icons/vuesax/linear/refresh-2.svg" alt="">
                Update Password
            </button>

            <ButtonTextLoader button-class="button button--success button-text-loader--content-width"
                loader-text="Verifying Information" loader-class="button-text-loader__loader-primary"
                :is-loading="buttonLoader" />
        </div>
    </form>
</template>

<script setup>
import ButtonTextLoader from '../../../common/ButtonTextLoader.vue'
import { ref, reactive } from 'vue'
import { customerServices } from '../Services/CustomerServices.js'
import { sweetAlerts } from '../../../helpers/SweetAlert.js'
import { useRouter } from 'vue-router'

const buttonLoader = ref(false)
const router = useRouter()
const { updatePasswordService, errors } = customerServices()
const { sweetSuccess } = sweetAlerts()
const password = reactive({
    password: '',
    newPassword: '',
    newPasswordConfirmation: '',
})

async function updatePassword() {
    const response = await updatePasswordService(password)

    if (response.hasErrors) return console.error(`Error updaing password: ${response.message}`)

    router.push('/')

    return sweetSuccess(response.data.message)
}
</script>