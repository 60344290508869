import { httpRequests } from '../../../helpers/HttpRequests.js'

export function customerServices() {
    const { getRequest, putRequest, httpErrors } = httpRequests()
    const errors = httpErrors

    function getAdminInformationService() {
        return getRequest('/web/admin_information')
    }

    function updateInformationService(information) {
        return putRequest('/web/admin_information/update', information)
    }

    function updatePasswordService(password) {
        return putRequest('/web/admin_information/update_password', password)
    }

    function updatePinService(pin) {
        return putRequest('/web/admin_information/update_pin', pin)
    }

    return {
        getAdminInformationService,
        updateInformationService,
        updatePasswordService,
        updatePinService,
        errors
    }
}