<template>
    <form class="form" @submit.prevent="updatePin">
        <div class="form__inputs-content">
            <div class="input-form">
                <label class="form__input-label form--required">
                    Old PIN
                </label>

                <input type="password" class="form__input" placeholder="****" v-model="pin.pin" maxlength="4">

                <div class="form__input-error" v-if="errors.fields.pin">
                    {{ errors.fields.pin[0] }}
                </div>
            </div>

            <div class="input-form">
                <label class="form__input-label form--required">
                    New PIN
                </label>

                <input type="password" class="form__input" placeholder="****" v-model="pin.newPin" maxlength="4">

                <div class="form__input-error" v-if="errors.fields.newPin">
                    {{ errors.fields.newPin[0] }}
                </div>
            </div>

            <div class="input-form">
                <label class="form__input-label form--required">
                    New PIN Confirmation
                </label>

                <input type="password" class="form__input" placeholder="****" v-model="pin.newPinConfirmation"
                    maxlength="4">

                <div class="form__input-error" v-if="errors.fields.newPinConfirmation">
                    {{ errors.fields.newPinConfirmation[0] }}
                </div>
            </div>
        </div>

        <div class="form__buttons">
            <button class="button button--success" v-if="!buttonLoader">
                <img src="@/assets/icons/vuesax/linear/refresh-2.svg" alt="">
                Update PIN
            </button>

            <ButtonTextLoader button-class="button button--success button-text-loader--content-width"
                loader-text="Verifying Information" loader-class="button-text-loader__loader-primary"
                :is-loading="buttonLoader" />
        </div>
    </form>
</template>

<script setup>
import ButtonTextLoader from '../../../common/ButtonTextLoader.vue'
import { ref, reactive } from 'vue'
import { customerServices } from '../Services/CustomerServices.js'
import { sweetAlerts } from '../../../helpers/SweetAlert.js'
import { useRouter } from 'vue-router'

const buttonLoader = ref(false)
const router = useRouter()
const { updatePinService, errors } = customerServices()
const { sweetSuccess } = sweetAlerts()
const pin = reactive({
    pin: '',
    newPin: '',
    newPinConfirmation: '',
})

async function updatePin() {
    const response = await updatePinService(pin)

    if (response.hasErrors) return console.error(`Error updaing PIN: ${response.message}`)

    router.push('/')

    return sweetSuccess(response.data.message)
}
</script>