<template>
    <form class="form" @submit.prevent="updateInformation">
        <div class="form__inputs-content">
            <div class="input-form">
                <label class="form__input-label form--required">
                    Email
                </label>

                <input type="text" class="form__input" placeholder="Admin Email" v-model="info.email">

                <div class="form__input-error" v-if="errors.fields.email">
                    {{ errors.fields.email[0] }}
                </div>
            </div>

            <div class="input-form">
                <label class="form__input-label form--required">
                    Username
                </label>

                <input type="text" class="form__input" placeholder="Admin Username" v-model="info.userName">

                <div class="form__input-error" v-if="errors.fields.userName">
                    {{ errors.fields.userName[0] }}
                </div>
            </div>

            <div class="input-form">
                <label class="form__input-label form--required">
                    Full Name
                </label>

                <input type="text" class="form__input" placeholder="Full Name" v-model="info.name">
                
                <div class="form__input-error" v-if="errors.fields.name">
                    {{ errors.fields.name[0] }}
                </div>
            </div>

            <div class="input-form">
                <label class="form__input-label form--required">
                    Phone
                </label>

                <input type="text" class="form__input" placeholder="Phone" v-model="info.phone">

                <div class="form__input-error" v-if="errors.fields.phone">
                    {{ errors.fields.phone[0] }}
                </div>
            </div>
        </div>

        <div class="form__buttons">
            <button class="button button--success" v-if="!buttonLoader">
                <img src="@/assets/icons/vuesax/linear/refresh-2.svg" alt="">
                Update Info
            </button>

            <ButtonTextLoader button-class="button button--success button-text-loader--content-width"
                loader-text="Verifying Information" loader-class="button-text-loader__loader-primary"
                :is-loading="buttonLoader" />
        </div>
    </form>

    <ContentLoader :hidden-loader="hiddenLoader" />
</template>

<script setup>
import ButtonTextLoader from '../../../common/ButtonTextLoader.vue'
import { ref, reactive, onMounted } from 'vue'
import { customerServices } from '../Services/CustomerServices.js'
import { sweetAlerts } from '../../../helpers/SweetAlert.js'
import { useRouter } from 'vue-router'
import { useClientStore } from '@/stores/ClientStore.js'
import ContentLoader from '@/common/ContentLoader.vue'

const hiddenLoader = ref(false)
const buttonLoader = ref(false)
const router = useRouter()
const { client } = useClientStore()
const { getAdminInformationService, updateInformationService, errors } = customerServices()
const { sweetSuccess } = sweetAlerts()
const info = reactive({
    name: '',
    email: '',
    userName: '',
    phone: ''
})

onMounted(async () => {
    // * Get admin information
    const adminInformation = await getAdminInformation()

    // * Hiden page loader
    hiddenLoader.value = true 

    // * Set admin information
    Object.assign(info, adminInformation)
})

async function getAdminInformation() {
    const response = await getAdminInformationService()

    if (response.hasErrors) return console.error(`Error getting admin information: ${response.message}`)

    return response.data
}

async function updateInformation() {
    buttonLoader.value = true 

    const response = await updateInformationService(info)

    if (response.hasErrors) {
        buttonLoader.value = false
         
        return console.error(`Error updaing information: ${response.message}`)
    } 

    // * Update customer name in local storage
    client.name = info.name

    router.push('/')

    return sweetSuccess(response.data.message)
}
</script>