<template>
    <div class="radio-inputs radio-inputs--row">
        <div class="radio-inputs__radio">
            <input type="radio" id="info" checked name="account-option" @change="selectedForm(InfoFormComponent)">
            <label for="info">Account Info</label>
        </div>

        <div class="radio-inputs__radio">
            <input type="radio" id="password" name="account-option" @change="selectedForm(PasswordFormComponent)">
            <label for="password">Account Password</label>
        </div>

        <div class="radio-inputs__radio">
            <input type="radio" id="pin" name="account-option" @change="selectedForm(PinFormComponent)">
            <label for="pin">Account PIN</label>
        </div>
    </div>
</template>

<script setup>
import InfoFormComponent from './InfoFormComponent.vue'
import PasswordFormComponent from './PasswordFormComponent.vue'
import PinFormComponent from './PinFormComponent.vue'
import { onMounted } from 'vue'

const emit = defineEmits(['selectedForm'])

onMounted(() => {
    emit('selectedForm', InfoFormComponent)
})

function selectedForm(formComponent) {
    emit('selectedForm', formComponent)
}
</script>